

















































































































import ContentColumnsService from '@/components/elements/ContentColumnsService.vue';
import ContentColumnsServices from '@/components/elements/ContentColumnsServices.vue';
import ContentSection from '@/components/elements/ContentSection.vue';
import {Component, Prop, Vue} from "vue-property-decorator";

@Component({
             components: {ContentColumnsService, ContentColumnsServices, ContentSection}
           })
export default class EntwicklungLeistungenSection extends Vue {
  @Prop({default: '00'}) private sectionNr!: string;
  @Prop({default: ''}) private sectionLabel!: string;
}
